export const glyphs = [
    {
        id: 1,
        name: 'DINGTA',
        iconUrl: '/pois/glyph1.webp',
    },
    {
        id: 2,
        name: 'JIIBA',
        iconUrl: '/pois/glyph2.webp',
    },
    {
        id: 3,
        name: "KEYYA YIB'",
        iconUrl: '/pois/glyph3.webp',
    },
    {
        id: 4,
        name: 'TEYII',
        iconUrl: '/pois/glyph4.webp',
    },
    {
        id: 5,
        name: 'WETZUU',
        iconUrl: '/pois/glyph5.webp',
    },
    {
        id: 6,
        name: 'CRUUJ',
        iconUrl: '/pois/glyph6.webp',
    },
    {
        id: 7,
        name: 'AMBA',
        iconUrl: '/pois/glyph7.webp',
    },
    {
        id: 8,
        name: 'PIMBII',
        iconUrl: '/pois/glyph8.webp',
    },
    {
        id: 9,
        name: "D'UN-YICH",
        iconUrl: '/pois/glyph9.webp',
    },
    {
        id: 10,
        name: 'HIMDE',
        iconUrl: '/pois/glyph10.webp',
    },
    {
        id: 11,
        name: 'AABER',
        iconUrl: '/pois/glyph11.webp',
    },
    {
        id: 12,
        name: 'ITKA',
        iconUrl: '/pois/glyph12.webp',
    },
    {
        id: 13,
        name: 'DZUU',
        iconUrl: '/pois/glyph13.webp',
    },
    {
        id: 14,
        name: "J'IJ",
        iconUrl: '/pois/glyph14.webp',
    },
    {
        id: 15,
        name: 'TSADZUI',
        iconUrl: '/pois/glyph15.webp',
    },
    {
        id: 16,
        name: 'HID',
        iconUrl: '/pois/glyph16.webp',
    },
    {
        id: 17,
        name: "B'EYEG",
        iconUrl: '/pois/glyph17.webp',
    },
    {
        id: 18,
        name: 'RIIGA',
        iconUrl: '/pois/glyph18.webp',
    },
    {
        id: 19,
        name: 'EYBUE',
        iconUrl: '/pois/glyph19.webp',
    },
    {
        id: 20,
        name: "CRUUJ'II",
        iconUrl: '/pois/glyph20.webp',
    },
    {
        id: 21,
        name: "D'UN-GA",
        iconUrl: '/pois/glyph21.webp',
    },
    {
        id: 22,
        name: "TAB'",
        iconUrl: '/pois/glyph22.webp',
    },
    {
        id: 23,
        name: "B'ENGYING",
        iconUrl: '/pois/glyph23.webp',
    },
    {
        id: 24,
        name: 'TSANGMIIJ',
        iconUrl: '/pois/glyph24.webp',
    },
    {
        id: 25,
        name: 'JICH',
        iconUrl: '/pois/glyph25.webp',
    },
    {
        id: 26,
        name: 'DUBUU',
        iconUrl: '/pois/glyph26.webp',
    },
];
