import { Anchor, Modal, Stack, Text } from '@mantine/core';

const AdsFallback = () => {
  return (
    <Modal
      opened
      title="⚠️ Ad Blocker Detected"
      onClose={() => null}
      withCloseButton={false}
      centered
    >
      <Stack spacing="xs">
        <p>
          I've noticed that you're using an ad blocker, which is preventing me
          from displaying ads on this site. As a solo developer, I rely on
          revenue from ads and subscriptions to continue developing projects
          like this one.
        </p>
        <Text fw={700} inline>
          Support Me:
        </Text>{' '}
        Consider becoming a Pro or Elite subscriber to remove ads and support
        the development of this website:
        <Anchor href="https://www.th.gl/support-me" target="_blank">
          Become a Subscriber
        </Anchor>
        <Text fw={700} inline>
          Keep Ads On:
        </Text>{' '}
        If you prefer not to subscribe, you can still support me by keeping ads
        enabled. They're closable for your convenience.
        <Text fw={700} inline>
          Need Help?
        </Text>{' '}
        If you have any questions or need assistance, feel free to join my
        Discord server for support:
        <Anchor href="https://www.th.gl/discord" target="_blank">
          Join my Discord server
        </Anchor>
        <p>Thank you for your understanding and support! 🙏</p>
      </Stack>
    </Modal>
  );
};

export default AdsFallback;
